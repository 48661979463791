import React from 'react'
import HeroImg from '../../../Assets/AboutUs/hero-image.png'
import HeroMobImage from '../../../Assets/AboutUs/hero-mobile-image.jpg'
const WhoWeAre = () => {
  return (
    <div className='max-w-[1600px] m-auto lg:mt-16 mt-8 '>
      <div className='xl:w-10/12 w-11/12 m-auto flex lg:flex-row flex-col-reverse justify-between items-center'>
        <div className='lg:w-[42%] w-full mt-10 lg:mt-0'>
          <h5 className=' text-[#F42C37]'>Welcome To Dawar</h5>
          <h1 className='text-5xl font-bold mt-3'>Who We Are ?</h1>
          <p className='mt-6 text-[rgba(0,0,0,0.60)] md:text-base text-[14px]'>
            When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage of my trees, and but a few stray.
          </p>
          <p className='mt-2 text-[rgba(0,0,0,0.60)] md:text-base text-[14px]'>
            gleams steal into the inner sanctuary, I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me.
          </p>
          <p className='mt-2 text-[rgba(0,0,0,0.60)] md:text-base text-[14px]'>
            when I hear the buzz of the little world among the stalks, and grow familiar with the countless indescribable forms of the insects and flies, then I feel the presence.
          </p>
          <button className=' font-[Inter] mt-7 text-xs py-3 px-4 bg-[#F42C37] text-white rounded-full'>KNOW MORE</button>
        </div>
        <div className='lg:w-1/2 w-full'>
          <img src={HeroImg} alt="hero-image" className='hidden md:block h-[25.8rem] object-cover' />
          <img src={HeroMobImage} alt="hero-image" className='md:hidden rounded-3xl' />
        </div>
      </div>
    </div>
  )
}

export default WhoWeAre;
