import React, { useEffect, useState } from 'react'
// import Blog1Img from '../../../Assets/Blog/blog1.jpg';
// import Blog2Img from '../../../Assets/Blog/blog2.jpg';
// import Blog3Img from '../../../Assets/Blog/blog3.jpg';
// import Blog4Img from '../../../Assets/Blog/blog4.jpg';
// import Blog5Img from '../../../Assets/Blog/blog5.jpg';
// import Blog6Img from '../../../Assets/Blog/blog6.jpg';
// import Blog7Img from '../../../Assets/Blog/blog7.jpg';
// import Blog8Img from '../../../Assets/Blog/blog8.jpg';
// import Blog9Img from '../../../Assets/Blog/blog9.jpg';
// import Blog10Img from '../../../Assets/Blog/blog10.jpg';
// import Blog11Img from '../../../Assets/Blog/blog11.jpg';
// import Blog12Img from '../../../Assets/Blog/blog12.jpg';
import { client } from '../../../sanity';
import imageUrlBuilder from '@sanity/image-url';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

// const blogCardImagesList = [Blog1Img, Blog2Img, Blog3Img, Blog4Img, Blog5Img, Blog6Img, Blog7Img, Blog8Img, Blog9Img, Blog10Img, Blog11Img, Blog12Img];

const BlogCards = () => {

  const [blogs, setBlogs] = useState([])

  const fetchData = async () => {
    const query = `*[_type == "blog"]`;
    const blogs = await client.fetch(query);
    // console.log("SANITY QUERY", blogs)
    setBlogs(blogs)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='max-w-[1600px] m-auto md:my-16 my-10'>
      <div className='xl:w-10/12 w-11/12 m-auto'>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-10'>
          {
            blogs?.length > 0 &&
            blogs?.map((el, i) => (
              <Link to={`/blog/${el._id}`} key={i}>
                <BlogCard key={i} el={el} />
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default BlogCards;


export const BlogCard = ({ el }) => {
  const builder = imageUrlBuilder(client)

  return (
    <div className='bg-[#F7F7F7] mb-7 md:mb-0 shadow-lg rounded-2xl '>
      {/* <img src={el} alt="Blog-img" className='rounded-t-2xl object-cover aspect-video' /> */}
      <img src={`${builder.image(el.blogimage).url()}`} alt='blog-image' className='rounded-t-2xl object-cover aspect-video' />
      <div className='p-4'>
        <div className='flex gap-x-4 font-[Montserrat] mt-4 text-[#6D6D6D] font-bold'>
          {/* <p>October 5, 2019</p> */}
          <div className=' flex gap-1'>
            <div className='flex'>
              <Moment format="MMM D" withTitle>
                {el.createdAt}
              </Moment>
              <p>,</p>
            </div>
            <Moment format="YYYY" withTitle>
              {el.createdAt}
            </Moment>
          </div>

          {/* <p>by paul</p> */}
        </div>
        <h3 className='text-xl font-bold mt-3 line-clamp-2'>{el.title}</h3>
        <p className='font-[Montserrat] mt-2 line-clamp-3 h-[4.3rem] text-[#4B4B4B]'>{el.metadesc}</p>
      </div>
    </div>
  )
}
