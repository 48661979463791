import React from 'react'

import tv from '../../../Assets/Product/smart-tv.svg';
import laptop from '../../../Assets/Product/laptop-2.svg'
import dellLaptop from '../../../Assets/Product/dell-g15.svg'
import sofa from '../../../Assets/Product/sofa.svg'
import pinkHeadphone from '../../../Assets/Product/pink-headphone.svg'
import washingMachine from '../../../Assets/Product/washing-machine.svg'


const productArray = [
    {
        img: tv,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: laptop,
        productName: "Rocky Mountain",
        price: "₹ 8,250",
    },
    {
        img: tv,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: dellLaptop,
        productName: "White EliteBook Tablet 810",
        price: "₹ 14490",
    },
    {
        img: sofa,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: pinkHeadphone,
        productName: "Wireless Audio System Multiroom 360",
        price: "₹ 8,250",
    },
    {
        img: tv,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: washingMachine,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
]

const BestProduct = () => {
    return (
        <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-16">
            <h2 className=' font-bold text-3xl text-center'>
                Best Seller Product
            </h2>
            <h3 className="text-black/30 font-['Montserrat'] text-center mt-2">
                Speaker There are many variations passages
            </h3>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-x-4 gap-y-8 items-stretch mt-10 md:mt-20'>
                {
                    productArray?.map((ele, i) => <ProductCard key={i} {...ele} />)
                }
            </div>

        </section>
    )
}

export default BestProduct



export const ProductCard = ({ img, productName, price }) => {
    return (
        <div className='overflow-hidden'>
            <div className='bg-[#F1F1F1] rounded-[20px] h-48 flex justify-center items-center'>
                <img src={img} alt={productName} className='py-2 md:w-60 w-52 ' />
            </div>
            <div className='ms-5 text-sm mt-3'>
                <p>
                    {productName}
                </p>
                <p className="font-bold font-['Montserrat'] text-lg mt-">
                    {price}
                </p>
            </div>
        </div>
    )
}