import React from 'react'
import LGImage from '../../Assets/AboutUs/lg.png';
import VivoImage from '../../Assets/AboutUs/vivo.png';
import IfbImage from '../../Assets/AboutUs/ifb.png';
import RealmeImage from '../../Assets/AboutUs/realme.png';
import SamsungImage from '../../Assets/AboutUs/samsung.png';
import OneplusImage from '../../Assets/AboutUs/one-plus.png';

const elactronicBrandsList = [
  {
    icon: LGImage,
    cssClass: 'xl:h-11 lg:h-8 md:h-5 h-11 '
  },
  {
    icon: VivoImage,
    cssClass: 'xl:h-10 lg:h-8 md:h-5 h-9 '
  }, {
    icon: IfbImage,
    cssClass: 'xl:h-10 lg:h-8 md:h-5 h-9 '
  }, {
    icon: RealmeImage,
    cssClass: 'xl:h-10 lg:h-8 md:h-5 h-9 '
  }, {
    icon: SamsungImage,
    cssClass: 'xl:h-20 lg:h-16 md:h-12 h-24'
  }, {
    icon: OneplusImage,
    cssClass: 'xl:h-24 lg:h-16 md:h-12 h-28'
  },
];


const ElectronicBrands = () => {
  return (
    <div className='max-w-[1600px] m-auto py-10 md:my-20 my-16' style={{ background: "linear-gradient(90deg, #E2E2E2 24%, #F5F5F5 100%)" }}>
      <div className='flex md:flex-row flex-row flex-wrap justify-evenly items-center '>
        {
          elactronicBrandsList?.map((el, i) => (
            <div key={i} className='h-20 px-6  flex  items-center justify-center'>
              <img src={el.icon} alt="electronics-brands" className={`${el.cssClass} opacity-60`} />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ElectronicBrands;
