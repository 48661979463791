import React from 'react'
import { Link } from 'react-router-dom'

import earPhone from '../../../Assets/Product/earphone.svg'
import watch from '../../../Assets/Product/digi-watch.svg'
import TV from '../../../Assets/Product/smart-tv.svg'
import fidge from '../../../Assets/Product/fridge.svg'
import laptop from '../../../Assets/Product/laptop.svg'
import spearker from '../../../Assets/Product/bt-spearker.svg'
import headphone from '../../../Assets/Product/black-headphone.svg'
import truckIcon from '../../../Assets/SVG_images/Free Shipping.svg';
import headphoneIcon from '../../../Assets/SVG_images/Group 1000005972.svg';
import moneyGarIcon from '../../../Assets/SVG_images/Group 1000005973.svg';
import paymentIcon from '../../../Assets/SVG_images/Group 1000005971.svg';



const cardData = [
    {
        title: "Free Shipping",
        info: "Free Shipping On All Order",
        icon: truckIcon
    },
    {
        title: "Money Guarantee",
        info: "30 Day Money Back",
        icon: moneyGarIcon
    }, {
        title: "Online Support 24/7",
        info: "Technical Support 24/7",
        icon: headphoneIcon
    }, {
        title: "Secure Payment",
        info: "All Cards Accepted",
        icon: paymentIcon
    },
]

const Product = () => {




    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-12'>
            <div className='grid lg:grid-cols-4 md:gap-x-6 md:grid-cols-2 grid-cols-1 md:gap-y-14 gap-y-5'>
                {/* First Div fro earphone */}
                <div style={{
                    borderRadius: "25px",
                    background: "linear-gradient(319deg, #363636 -0.05%, #000 109.84%)",
                    position: "relative",
                }}
                    className='flex justify-center pt-6 w-full flex-col text-white h-64 px-5 overflow-hidden max-w-[270px] mx-auto xl:max-w-none shadow-xl'
                >
                    <div className='h-24 '>
                        <p className='z-10'>
                            Enjoy
                        </p>
                        <p className='font-semibold z-10 text-xl'>
                            With
                        </p>
                        <p className=" uppercase font-['Montserrat'] text-[#444444] font-black text-3xl z-10">
                            earphone
                        </p>
                    </div>

                    <div className='self-start z-10 mt-6'>
                        <Link to={'/shop'} className="p-1 bg-[#F42C37] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute top-[42%] left-0 -translate-y-1/2'>
                        <img src={earPhone} alt="earphone " />
                    </div>
                </div>
                {/* First Div End */}
                {/* Second Div for watch */}
                <div style={{
                    borderRadius: "25px",
                    background: "#FEC62E",
                    position: "relative",
                }}
                    className='flex justify-center pt-6 w-full flex-col text-white h-64 px-5 overflow-hidden max-w-[270px] mx-auto xl:max-w-none shadow-xl'
                >
                    <div className='h-24 '>
                        <p className='z-10'>
                            New
                        </p>
                        <p className='font-semibold z-10 text-xl'>
                            Wear
                        </p>
                        <p className=" uppercase font-['Montserrat'] text-[#FFD76A] font-black text-3xl z-10">
                            GADGETS
                        </p>
                    </div>
                    <div className=' z-10 mt-6'>
                        <Link to={'/shop'} className="p-1 bg-[#fff] text-[#FEC62E] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute top-0 right-0 z-0'>
                        <img src={watch} alt="Digi Watch" className='w-32' />
                    </div>
                </div>
                {/* second Div end */}
                {/* third div for TV */}
                <div style={{
                    borderRadius: "25px",
                    background: "linear-gradient(90deg, #F42C37 70.79%, #FF4E59 99.87%)",
                    position: "relative",
                }}
                    className='flex justify-center pt-6 w-full flex-col text-white h-64 px-5 overflow-hidden max-w-[270px]   mx-auto md:col-span-2 md:max-w-none shadow-xl'
                >
                    <div className='h-24 '>
                        <p className='z-10'>
                            Trend
                        </p>
                        <p className='font-semibold z-10 text-xl'>
                            Devices
                        </p>
                        <p className="font-['Montserrat'] text-[#FFD76A] font-black text-4xl z-10">
                            Television
                        </p>
                    </div>
                    <div className=' z-10 mt-6'>
                        <Link to={'/shop'} className="p-1 bg-[#fff] text-[#F42C37] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute top-1/2 right-0 -translate-y-1/2 '>
                        <img src={TV} alt="smart Tv" className='w-60' />
                    </div>
                </div>
                {/* third div end*/}

                {/* fourth div for fridge */}
                <div style={{
                    borderRadius: "25px",
                    background: "linear-gradient(90deg, #EDECED 0.09%, rgba(238, 240, 243, 0.80) 117.07%)",
                    position: "relative",
                }}
                    className='hidden md:flex justify-center py-10 items-start w-full flex-col  h-64 px-5  max-w-[270px] mx-auto md:col-span-2 md:max-w-none  shadow-xl'
                >
                    <p className='z-10'>
                        KEEP
                    </p>
                    <p className='font-semibold z-10 text-xl'>
                        Gaming
                    </p>
                    <p className=" uppercase font-['Montserrat'] text-[#fff] font-black text-3xl md:text-4xl z-10">
                        refrigerator
                    </p>
                    <div className=' z-10 mt-6'>
                        <Link to={'/shop'} className="p-1 bg-[#F42C37] text-[#fff] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute top-[25%] right-[5%] -translate-y-1/ '>
                        <img src={fidge} alt="fidge" className='w-36' />
                    </div>
                </div>
                {/* fourth div end */}
                {/* fifth div for laptop */}
                <div style={{
                    borderRadius: "25px",
                    background: "#2DCC6E",
                    position: "relative",
                }}
                    className='flex justify-start py-10 items-start w-full flex-col text-white h-64 px-5 overflow-hidden max-w-[270px] mx-auto xl:max-w-none shadow-xl'
                >
                    <p className='z-10'>
                        Play
                    </p>
                    <p className='font-semibold z-10 text-xl'>
                        Game
                    </p>
                    <p className=" uppercase font-['Montserrat'] text-[#57D68B] font-black text-3xl z-10">
                        Laptop
                    </p>
                    <div className=' z-10 mt-16'>
                        <Link to={'/shop'} className="p-1 bg-[#fff] text-[#FEC62E] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute top-1/2 -translate-y-1/2 right-0 '>
                        <img src={laptop} alt="laptop" className='w-' />
                    </div>
                </div>
                {/* fifth div end */}
                {/* sixth div for bt-speaker */}
                <div style={{
                    borderRadius: "25px",
                    background: "linear-gradient(180deg, #1478FF 0%, #1891FF 100%)",
                    position: "relative",
                }}
                    className='flex justify-start py-10 items-start w-full flex-col text-white h-64 px-5 overflow-hidden max-w-[270px] mx-auto xl:max-w-none shadow-xl'
                >
                    <p className='z-10'>
                        New
                    </p>
                    <p className='font-semibold z-10 text-xl'>
                        Amazon
                    </p>
                    <p className=" uppercase font-['Montserrat'] text-[#3A98FF] font-black text-3xl z-10">
                        speaker
                    </p>
                    <div className=' z-10 mt-16'>
                        <Link to={'/shop'} className="p-1 bg-[#fff] text-[#1478FF] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute bottom-0 '>
                        <img src={spearker} alt="speaker" className='w-full' />
                    </div>
                </div>
                {/* sixth div end */}
                <div style={{
                    borderRadius: "25px",
                    background: "linear-gradient(90deg, #EDECED 0.09%, rgba(238, 240, 243, 0.80) 117.07%)",
                    position: "relative",
                }}
                    className='md:hidden flex justify-center py-10 items-start w-full flex-col  h-64 px-5  max-w-[270px] mx-auto md:col-span-2 md:max-w-none  shadow-xl'
                >
                    <p className='z-10'>
                        KEEP
                    </p>
                    <p className='font-semibold z-10 text-xl'>
                        Gaming
                    </p>
                    <p className=" uppercase font-['Montserrat'] text-[#fff] font-black text-3xl md:text-4xl z-10">
                        refrigerator
                    </p>
                    <div className=' z-10 mt-6'>
                        <Link to={'/shop'} className="p-1 bg-[#F42C37] text-[#fff] text-xs rounded-full px-3 font-['Montserrat'] hover:text-black transition-all duration-100 ease-linear">
                            Browse
                        </Link>
                    </div>
                    <div className='absolute top-[25%] right-[5%] -translate-y-1/ '>
                        <img src={fidge} alt="fidge" className='w-36' />
                    </div>
                </div>
                <div className='md:hidden grid grid-cols-2 gap-2 mt-16 -mb-6'>
                    {
                        cardData?.map((el, i) => (
                            <div key={i} className=' text-center flex flex-col items-center p-3 gap-y-1'>
                                <div className='h-12'>
                                    <img src={el.icon} alt={el.title} className='' />
                                </div>
                                <h5 className='font-bold text-[14px] mt-2'>{el.title}</h5>
                                <p className="text-xs text-[#AAAAAA] font-['Montserrat']">{el.info}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div
                style={{
                    background: "radial-gradient(50.39% 24.24% at 42.63% 50%, #F24C55 48.96%, #F42C37 100%)",
                }}
                className="w-full mt-40 md:mt-60 text-white font-['Montserrat'] rounded-3xl pt-16 pb-12 relative  shadow-xl"
            >
                <div className='w-10/12 mx-auto flex justify-between flex-col md:flex-row text-center md:text-left'>
                    <div className='md:w-5/12 mt-5 md:mt-0'>
                        <div className='md:w-10/12 ms-auto'>
                            <p className='text-sm font-light'>
                                20% off
                            </p>
                            <p className='font-black text-3xl md:text-5xl uppercase lg:w-1/2' >
                                Fine Smile
                            </p>
                            <p className='text-sm font-light'>
                                15 Nov 7 Dec
                            </p>
                        </div>
                    </div>

                    <div className='absolute w-full md:w-2/4 top-0 -translate-y-[50%] left-1/2 -translate-x-1/2'>
                        <img src={headphone} alt="headphone" />
                    </div>
                    <div className='md:w-4/12'>
                        <p className='text-sm font-medium'>
                            Beats Solo Air
                        </p>
                        <p className='font-black text-3xl md:text-4xl  ' >
                            Summer Sale
                        </p>
                        <p className='text-sm font-light'>
                            Company that’s grown from 270 to 480
                            employees in the last 12 months.
                        </p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Product