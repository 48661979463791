import React, { useEffect, useState } from 'react'
import { Input, Button } from "@material-tailwind/react";
import { GoSearch } from "react-icons/go";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

import { ProductCard } from '../../Landingpage/BestProduct/BestProduct'

import tv from '../../../Assets/Product/smart-tv.svg'
import tv2 from '../../../Assets/Product/smart-tv-2.svg'
import tv3 from '../../../Assets/Product/smart-tv-3.svg'
import tv4 from '../../../Assets/Product/smart-tv-4.svg'
import tv5 from '../../../Assets/Product/smart-tv-5.svg'
import tv6 from '../../../Assets/Product/smart-tv-6.svg'
import tv7 from '../../../Assets/Product/smart-tv-7.svg'

const productArray = [
    {
        img: tv,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv,
        productName: "LG UHD TV UR80 65",
        price: "₹ 91071",
    },
    {
        img: tv2,
        productName: "LG UHD TV UR75 43",
        price: "₹ 43507",
    },
    {
        img: tv3,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv4,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv5,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv6,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv7,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv6,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },
    {
        img: tv7,
        productName: "LG LQ643 32",
        price: "₹ 14490",
    },

]


const Shop = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [startPage, setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(0)

    const [newArray, setNewArray] = useState([])

    const itemsPerPage = 9;
    const totalItems = productArray.length;

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handlePrevClick = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const generateMultiplicationTable = (page) => {
        const start = (page - 1) * itemsPerPage;
        const end = page * itemsPerPage - 1;
        const table = productArray?.filter((ele, index) => {
            return index <= end && index >= start
        })
        setStartPage(start)
        setEndPage(end)
        setNewArray(table)
    }

    useEffect(() => {
        generateMultiplicationTable(currentPage)
        window.scroll(0, 0)
    }, [currentPage])
    const renderNumberButtons = () => {
        const buttons = [];
        for (let i = 1; i <= totalPages; i++) {
            buttons.push(
                <Button
                    size="md"
                    className={`px-2 rounded mx-1 py-1.5 ${currentPage === i ? "bg-[#E62A35]" : "bg-white text-black"}`}
                    key={i}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </Button>

            );
        }
        return buttons;
    };
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12'>
            <div className='mt-10 flex flex-col-reverse md:flex-col '>
                <div className='flex items-center gap-x-6'>
                    <div className="relative flex w-full max-w-[20rem] mt-20 md:mt-0">
                        <Input
                            style={{
                                background: "linear-gradient(91deg, #D9D9D9 2.65%, rgba(217, 217, 217, 0.12) 90.02%, rgba(217, 217, 217, 0.00) 101.62%)"
                            }}
                            type="email"
                            label="Email Address"
                            className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-none focus:!border-t-gray-900 focus:ring-0 rounded-full font-['Montserrat']"
                            labelProps={{
                                className: "hidden",
                            }}
                            placeholder='Search Here'
                            containerProps={{
                                className: "min-w-0",
                            }}
                        />
                        <Button
                            size="sm"
                            // disabled={!email}
                            className="!absolute right-1 px-2 top-1 rounded-full bg-[#F42C37] shadow-md shadow-[#F42C37]"
                        >
                            <GoSearch size={15} />
                        </Button>
                    </div>
                    <div className='hidden md:block'>
                        <p className='text-3xl font-bold'>
                            Shop
                        </p>
                        <p className='text-sm'>
                            Showing Result for {startPage + 1} - {endPage + 1 > productArray.length ? productArray.length : endPage} from {productArray.length}
                        </p>
                    </div>
                </div>
                <div className='grid md:grid-cols-3 grid-cols-1 md:gap-x-2 lg:gap-x-6 gap-y-8 lg:w-10/12 mx-auto mt-16'>
                    {
                        newArray?.map((ele, index) => <ProductCard key={index} {...ele} />)
                    }
                    {
                        console.log(newArray)
                    }
                </div>
                <div className='lg:w-10/12 mx-auto mt-10 flex items-stretch'>
                    <Button
                        size="sm"
                        onClick={handlePrevClick}
                        disabled={currentPage === 1}
                        className="px-2 rounded bg-white text-black"
                    >
                        <IoMdArrowBack />
                    </Button>
                    {renderNumberButtons()}
                    <Button
                        size="sm"
                        onClick={handleNextClick} disabled={currentPage === totalPages}
                        className="px-2 rounded bg-white text-black"
                    >
                        <IoMdArrowForward />
                    </Button>
                </div>
            </div>
        </section>
    )
}


export default Shop