import React, { useEffect } from 'react'
import Herosection from '../Components/Herosection/Herosection'
import WhoWeAre from '../Components/Aboutus/WhoWeAre/WhoWeAre'
import SalesDeatils from '../Components/Aboutus/SalesDeatils/SalesDeatils'


const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div>
      <Herosection title='About Us' />
      <WhoWeAre />
      <SalesDeatils />
    </div>
  )
}

export default AboutUs
