import React from 'react'

import Herosection from '../Components/Herosection/Herosection'
import Shop from '../Components/ProductPage/Shop/Shop'

const ProductPage = () => {
    
    return (
        <main>
            <Herosection title={"Products"} />
            <Shop />
        </main>
    )
}

export default ProductPage