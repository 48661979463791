import React from 'react'
import { GiSmartphone } from "react-icons/gi";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { IoWatchOutline, IoCameraOutline } from "react-icons/io5";
import { PiHeadphonesLight } from "react-icons/pi";
import { LuGamepad } from "react-icons/lu";

import smartWatch from '../../../Assets/Product/smart-watch.svg'

const categoryArray = [
    {
        icon: <GiSmartphone size={40} />,
        title: "Phone"
    },
    {
        icon: <HiOutlineDesktopComputer size={40} />,
        title: "Computers"
    },
    {
        icon: <IoWatchOutline size={40} />,
        title: "SmartWatch"
    },
    {
        icon: <IoCameraOutline size={40} />,
        title: "Camera"
    },
    {
        icon: <PiHeadphonesLight size={40} />,
        title: "HeadPhones"
    },
    {
        icon: <LuGamepad size={40} />,
        title: "Gaming"
    },
]

const Category = () => {
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-9/12 mt-16'>
            <h2 className="text-2xl text-center font-semibold font-['Inter']">
                Browse By Category
            </h2>
            <div className='grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 md:gap-x-4 gap-y-4 mt-10 md:mt-16 '>
                {
                    categoryArray?.map((ele) => <div key={ele.title} className='w-full h-[145px] border border-black/30 flex justify-center items-center flex-col rounded-3xl transition-all duration-200 ease-linear hover:bg-[#2DCC6E] hover:border-none hover:text-white max-w-[150px] mx-auto'>
                        <div>
                            {ele.icon}
                        </div>
                        <div className='mt-2'>
                            <p>
                                {ele.title}
                            </p>
                        </div>
                    </div>)
                }
            </div>
            <div
                style={{
                    background: "radial-gradient(150.95% 74.87% at 50% 50%, #51EC90 0%, #2DCC6E 33.85%)",
                }}
                className="w-full mt-44 3xl:mt-72 lg:mt-56 md:mt-52 text-white font-['Montserrat'] rounded-3xl pt-16 pb-12 relative"
            >
                <div className='w-10/12 mx-auto flex justify-between flex-col md:flex-row text-center md:text-left'>
                    <div className='md:w-5/12 mt-5 md:mt-0'>
                        <div className='md:w-10/12 ms-auto'>
                            <p className='text-sm font-light z-10 relative'>
                                20% off
                            </p>
                            <p className='font-black text-3xl md:text-5xl uppercase w-8/12 mx-auto md:mx-0 z-20 relative lg:w-1/2' >
                                Happy Hours
                            </p>
                            <p className='text-sm font-light'>
                                15 Nov 7 Dec
                            </p>
                        </div>
                    </div>
                    <div className='absolute w-full md:w-2/4 top-0 -translate-y-[50%] left-1/2 -translate-x-1/2'>
                        <img src={smartWatch} alt="smart-watch" />
                    </div>
                    <div className='md:w-4/12'>
                        <p className='text-sm font-medium'>
                            Beats Solo Air
                        </p>
                        <p className='font-black text-2xl md:text-4xl  z-10 relative' >
                            Summer Sale
                        </p>
                        <p className='text-sm font-light'>
                            Company that’s grown from 270 to 480
                            employees in the last 12 months.
                        </p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Category