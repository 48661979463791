import React, { useEffect } from 'react'
import Herosection from '../Components/Landingpage/Herosection/Herosection'
import Product from '../Components/Landingpage/Product/Product'
import BestProduct from '../Components/Landingpage/BestProduct/BestProduct'
import Category from '../Components/Landingpage/Category/Category'
import Blogs from '../Components/Landingpage/Blogs/Blogs'
import Testimonial from '../Components/Landingpage/Testimonial/Testimonial'
import ProductMobile from '../Components/Landingpage/ProductMobile/ProductMobile'

const Landingpage = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <main>
            <Herosection />
            <div className='md:block hidden'>
                <Product />
            </div>
            <div className='block md:hidden '>
                <ProductMobile />
            </div>
            <BestProduct />
            <Category />
            <Blogs />
            <Testimonial />
        </main>
    )
}

export default Landingpage