import React from 'react'
import MoneyBagIcon from '../../../Assets/AboutUs/Icon-Moneybag.svg';
import SaleIcon from '../../../Assets/AboutUs/Icon-Sale.svg';
import ShopIcon from '../../../Assets/AboutUs/Icon-Shop.svg';
import ShoppingBagIcon from '../../../Assets/AboutUs/Icon-Shopping bag.svg';

const salesDetailData=[
    {
        title:"Sallers active our site",
        icon:ShopIcon,
        salesNum:10.5
    },
    {
        title:"Mopnthly Produduct Sale",
        icon:SaleIcon,
        salesNum:33
    },    {
        title:"Customer active in our site",
        icon:ShoppingBagIcon,
        salesNum:45.5
    },    {
        title:"Anual gross sale in our site",
        icon:MoneyBagIcon,
        salesNum:25
    },
]
const SalesDeatils = () => {
  return (
    <div className='max-w-[1600px] m-auto mt-20'>
      <div className='xl:w-10/12 w-11/12 m-auto flex justify-center'>

       <div className=' flex md:flex-row md:flex-wrap lg:flex-nowrap md:justify-center flex-col md:gap-5 gap-y-3'>
        {
            salesDetailData?.map((el,i)=>(
                <SalesCard el={el} key={i} id={i}/>
            ))
        }
       </div>
      </div>
    </div>
  )
}

export default SalesDeatils;


const SalesCard=({el,id})=>{

    return(
        <div className={`w-60 h-48 flex flex-col items-center justify-center gap-3 border border-black/30 rounded-sm  ${id === 1 && "bg-[#2DCC6E] shadow-[0px_2px_10px_2px_rgba(0,0,0,0.20)] border-none"}`}>
            <div className={`${id ===1 ?"bg-white/30":"bg-[#2F2E30]/30"} w-[62px] h-[62px] flex justify-center items-center  rounded-full`}>
                <div className={`${id ===1 ?"bg-white":"bg-black"} w-11 h-11 flex justify-center items-center rounded-full`}>
                    <img src={el.icon} alt={el.title} className={`${id===0 ?"w-6":"w-7"}`} />
                </div>
            </div>
            <h2 className=' text-3xl font-bold'>{el.salesNum}K</h2>
            <h5>{el.title}</h5>

        </div>
    )
}
