import React, { useEffect } from 'react'
import Herosection from '../Components/Herosection/Herosection'
import ContactUsForm from '../Components/ContactUsPage/ContactUsForm'

const ContactUs = () => {
    useEffect(() => {
        window.scroll(0, 0)
    })
    return (
        <main>
            <Herosection title={"Contact Us"} />
            <ContactUsForm />
        </main>
    )
}

export default ContactUs