import React from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';

import quote from '../../../Assets/SVG_images/quote.svg'
import quoteLight from '../../../Assets/SVG_images/quote-light.svg'

const testimonialArray = [
    {
        desc: "Exceptional service and quality products! Dawar Home Appliances has been my go-to for electronic components for years. Their commitment to excellence and prompt delivery make them stand out in the industry.",
        bgColor: "#fff",
        textColor: "#000",
        bgImg: quote
    },
    {
        desc: "I've been consistently impressed with Dawar Home Appliances' dedication to providing top-notch wholesale electronic components. Their attention to detail and commitment to customer satisfaction are truly commendable. A reliable partner for any business in need of quality products",
        bgColor: "#7CC9FE",
        textColor: "#fff",
        bgImg: quoteLight
    },
    {
        desc: "I can't recommend Dawar Home Appliances enough! Their attention to detail and prompt service have been instrumental in ensuring our projects run smoothly. Their expertise in electronic components is unmatched, and their professionalism sets them apart. A fantastic choice for anyone seeking reliable wholesale suppliers.",
        bgColor: "#fff",
        textColor: "#000",
        bgImg: quote
    },
]

const Testimonial = () => {
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-16'>
            <div style={{
                background: "linear-gradient(0deg, #D9EFFF -24.87%, #97D3FF 106.39%)",
            }}
                className='pt-8 rounded-3xl lg:pb-20 pb-12 relative '
            >
                <div className='absolute top-1/2 w-11/12 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <p className="font-['Montserrat'] font-bold text-[5rem] md:text-[12rem] lg:text-[16rem] text-[#D9EFFF]  md:block md:w-full mx-auto">
                        Dawar
                    </p>

                </div>
                <h2 className="text-[#FF0404] text-center text-2xl font-['Montserrat'] relative z-10">
                    Testimonials
                </h2>
                <h3 className='md:text-3xl text-2xl px-2 font-medium text-center mt-1 relative z-10'>
                    What they are saying about us
                </h3>
                <div className=' md:grid-cols-3 md:gap-x-2 lg:gap-x-6 gap-y-6 w-11/12 mx-auto mt-10  hidden lg:grid '>

                    {
                        testimonialArray?.map((ele, i) => <div key={i} className='relative text-xs  px-8 py-12 flex justify-center items-center rounded'
                            style={{
                                filter: " drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.25))",
                                background: ele.bgColor,
                                color: ele.textColor,
                            }}
                        >
                            <p className=' relative z-10'>
                                {ele.desc}
                            </p>
                            <div className='absolute'>
                                <img src={ele.bgImg} alt="double-quote" />
                            </div>
                        </div>)
                    }

                </div>
                <div className=' mt-10 lg:hidden w-11/12 m-auto'>
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false
                        }}
                        modules={[Autoplay]}
                        className='mySwiper'
                        breakpoints={{
                            300: {
                              slidesPerView: 1,
                              spaceBetween: 10,
                            },
                            600: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                        }}
                    >
                        {
                            testimonialArray?.map((ele, i) => <SwiperSlide key={ele.desc} >
                                <div className='mx-auto relative text-xs  px-8 py-12 flex justify-center items-center shadow-md h-[250px] rounded-3xl'
                                    style={{
                                        background: ele.bgColor,
                                        color: ele.textColor,
                                    }}
                                >
                                    <p className=' relative z-10'>
                                        {ele.desc}
                                    </p>
                                    <div className='absolute'>
                                        <img src={ele.bgImg} alt="double-quote" />
                                    </div>
                                </div>
                            </SwiperSlide>)

                        }
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Testimonial