import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../Assets/Logo/logo.svg'
import qrCode from '../../Assets/SVG_images/LocationQrcode.svg'

const quickLinks = [
  {
    title: "Home",
    links: "/",
  },
  {
    title: "Shop",
    links: "/shop",
  },
  {
    title: "About Us",
    links: "/about-us",
  },
  {
    title: "Blog",
    links: "/blogs",
  },
  {
    title: "Contact Us",
    links: "/contact-us",
  },
]

const blankArray = [
  {
    title: "Mobiles",
    links: "#"
  },
  {
    title: "Tablet",
    links: "#"
  },
  {
    title: "Air Conditioners",
    links: "#"
  },
  {
    title: "Home Theaters",
    links: "#"
  },
  {
    title: "Accessories",
    links: "#"
  },
]

const exploreCollections = [
  {
    title: "Air Purifiers",
    links: "#",
  },
  {
    title: "Chimney & Cooktops",
    links: "#",
  },
  {
    title: "Geysers",
    links: "#",
  },
  {
    title: "Fans",
    links: "#",
  },
  {
    title: "Chairs",
    links: "#",
  },
]
const blankArray2 = [
  {
    title: "Dining Table",
    links: "#"
  },
  {
    title: "Bed",
    links: "#"
  },
  {
    title: "Bookshelf",
    links: "#"
  },
  {
    title: "Almirah & Wardrobe",
    links: "#"
  },
  {
    title: "LED TV",
    links: "#"
  },
]
const contact = [
  {
    title: "+91 98 22 822233",
    links: "tel:+919822822233",
  },
  {
    title: "+91 82 97 957746",
    links: "tel:+918297957746",
  },
  {
    title: "dawarhomeappliances@gmail.com",
    links: "mailto:dawarhomeappliances@gmail.com",
  },
  {
    title: "Dawar Home Appliances, Near Bank of Maharashtra, Tadoba Road, Tukum, Chandrapur, 442401",
    links: "https://maps.app.goo.gl/FeDAsskrWRew4ttg7"
  }
]


const Footer = () => {
  return (
    <footer >
      <div className='w-11/12 mx-auto max-w-[1600px]'>
        <div className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-7 gap-y-5 items-start  md:items-end mb-24'>
          <div className='col-span-2 xl:col-span-1 flex justify-center items-center md:block flex-col'>
            <img src={Logo} alt="dawar-logo" className='' />
            <p className="text-sm font-['Arial']">
              An innovative hub for wholesale electronics, Dawar Home Appliances delivers excellence with quality, expertise, and unwavering commitment to clients.
            </p>
          </div>
          <div className='lg:w-max lg:mx-auto'>
            <h4 className='font-bold md:text-left mb-7 max-w-[250px] md:max-w-none mx-auto uppercase'>
              Quick Links
            </h4>
            <div className='flex flex-col text-sm md:text-left max-w-[250px] md:max-w-none mx-auto gap-y-1 capitalize'>
              {
                quickLinks?.map((ele) => <Link key={ele.title} to={ele.links}>{ele.title}</Link>)
              }
            </div>
          </div>
          <div className='md:hidden flex flex-col justify-center items-center'>
            <h2 className='font-semibold text-sm md:text-center mb-5  md:max-w-none mx-auto' >
              Scan for our location
            </h2>
            <div className='flex justify-center items-center'>
              <img src={qrCode} alt="qr-code-dawar-electronics" />
            </div>
          </div>
          <div className='col-span-2 md:col-span-3 '>
            <h4 className='font-bold text-center mb-7 max-w-[250px] md:max-w-none mx-auto'>
              EXPLORE COLLECTION
            </h4>
            <div className='grid grid-cols-2 md:flex items-center gap-x-8 md:justify-evenly flex-wrap md:flex-nowrap'>
              <div className='lg:w-max capitalize'>
                <div className='flex flex-col text-sm md:text-left max-w-[250px] md:max-w-none mx-auto gap-y-1'>
                  {
                    blankArray?.map((ele) => <Link key={ele.title} to={ele.links}>{ele.title}</Link>)
                  }
                </div>
              </div>
              <div className='lg:w-max lg:mx-aut capitalize'>

                <div className='flex flex-col text-sm md:text-left max-w-[250px] md:max-w-none mx-auto gap-y-1 '>
                  {
                    exploreCollections?.map((ele) => <Link key={ele.title} to={ele.links}>{ele.title}</Link>)
                  }
                </div>
              </div>
              <div className='w-full md:w-auto col-span-2'>
                <div className=' grid grid-cols-2 gap-x-8 md:gap-x-0  md:flex  md:flex-col text-sm md:text-left w-full md:max-w-none mx-auto gap-y-1 md:w-max'>
                  {
                    blankArray2?.map((ele) => <Link key={ele.title} to={ele.links}>{ele.title}</Link>)
                  }
                </div>
              </div>
            </div>
          </div>


          <div className='col-span-2 '>
            <h4 className='font-bold uppercase md:text-left mb-10  md:max-w-none mx-auto col-span-2 md:col-span-1'>
              Reach Out to Us
            </h4>
            <div className='flex flex-col text-sm md:text-left  md:max-w-none mx-auto gap-y-1'>
              <div className='flex items-start gap-x-2'>
                <span className='font-bold'>
                  Contact:
                </span>
                <span className='text-sm'>
                  <a href="tel:+91 98 22 822233" className=''>+91 98 22 822233</a> |

                  <a href="tel:+91 82 97 957746" className=''>+91 82 97 957746</a><br />
                  <a href="mailto:dawarhomeappliances@gmail.com" className=' break-all'>dawarhomeappliances@gmail.com</a>

                </span>
              </div>
              <div className='flex items-start gap-x-2 '>
                <span className='font-bold'>
                  Address:
                </span>
                <a className='md:text-sm' href="https://maps.app.goo.gl/FeDAsskrWRew4ttg7" target="_blank" rel="noopener noreferrer">
                  Dawar Home Appliances, Near Bank of Maharashtra, Tadoba Road, Tukum, Chandrapur, 442401
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="bg-black py-5 md:px-20 text-white/50 font-['Arial'] text-sm flex justify-between items-center flex-col-reverse gap-y-2 md:gap-y-0 md:flex-row">
        <h2>
          ©All rights reserved by Dawar Mobile & Homeappliances
        </h2>
        <div className='flex gap-x-2 font-medium'>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <p>.</p>
          <Link to="/terms-condition">Term Condition</Link>
        </div>
        <div>
          <p>
            Designed & Developed by GBJ Buzz Pvt. Ltd
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
