import React from 'react'

import From from './From'

import location from '../../Assets/SVG_images/location.svg'
import phone from '../../Assets/SVG_images/phone.svg'
import mail from '../../Assets/SVG_images/message.svg'

const ContactUsForm = () => {
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12'>
            <div className='flex flex-col md:flex-row md:justify-between items-center gap-y-10 mt-20 '>

                <div className='md:w-5/12'>
                    <From />
                </div>
                <div className='md:w-1/2 w-full '>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.836865929199!2d79.2995675!3d19.973361699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd2d5db1e1c5dd3%3A0x4061ed8263ef43d8!2sDawar%20Home%20Appliances%2C%20Mobiles%20%26%20Enterprises!5e0!3m2!1sen!2sin!4v1701348329919!5m2!1sen!2sin"
                        title='dawar-homeapplience'
                        height="450"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        className='rounded-2xl w-full'
                    ></iframe>
                </div>
            </div>
            <div className='flex flex-col md:flex-wrap lg:flex-nowrap md:flex-row justify-evenly items-center gap-y-5 mt-16 '>
                <div className='flex justify-center items-center gap-x-10 md:flex-row flex-col md:text-base text-xs test-center'>
                    <div>
                        <img src={mail} alt="email-svg" className='md:w-16 w-12 mb-4 md:mb-0' />
                    </div>
                    <div className='flex flex-col md:text-left text-center'>

                        <a href="mailto:info@yourdomain.com">
                            <span className='font-bold'>Email:</span> Info@yourdomain.com
                        </a>
                        <a href="mailto:info@samplemail.com">
                            info@samplemail.com
                        </a>
                    </div>
                </div>
                <div className='hidden md:flex justify-center items-center gap-x-10 md:flex-row flex-col md:text-base text-xs'>
                    <div>
                        <img src={phone} alt="phone-svg" className='w-10 mb-4 md:mb-0' />
                    </div>
                    <div>
                        <div className='flex justify-start items-center'>
                            <p className='font-bold'>
                                Phone :
                            </p>
                            <a href="tel:info@yourdomain.com">
                                +99 (0) 101 0000 888
                            </a>
                        </div>
                        <a href="tel:info@samplemail.com">
                            +99 (0) 101 0000 888
                        </a>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-x-5 lg:w-[30%] md:flex-row flex-col md:text-base text-xs'>
                    <div>
                        <img src={location} alt="location-svg" className='xl:w-20 w-7 mb-4 md:mb-0' />
                    </div>
                    <div>
                        <div className='flex md:justify-start justify-center md:text-left text-center items-start '>
                            {/* <p className='font-bold'>
                                Address:
                            </p> */}
                            <a href="https://maps.app.goo.gl/FeDAsskrWRew4ttg7" target="_blank" rel="noopener noreferrer"
                                className=' break-words '
                            >
                                <span className='font-bold'>Address:</span>  &nbsp; Waghoba Chowk , Near Bank of Maharashtra , Tadoba Road , Tukum , Chandrapur , MH
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ContactUsForm