import React from 'react'
import { IoChevronForward } from "react-icons/io5";

const Herosection = ({title}) => {
    // console.log("TTTTT",title)
  return (
    <div className='bg-[#D9D9D9]'>
        <div className='max-w-[1600px] m-auto'>
            <div className='w-10/12 m-auto text-center py-10'>
              <div className='flex justify-center items-center gap-x-2 font-[Montserrat] text-[rgba(0,0,0,0.60)]'>
                <h5>Home </h5>
                <IoChevronForward className='text-[rgba(0,0,0,0.60)]' />
                <h5>{title}</h5>
              </div>
              <div>
                <h1 className='text-4xl font-bold mt-3'>{title}</h1>
              </div>
            </div>
        </div>
      
    </div>
  )
}

export default Herosection;
