import React from 'react'
import {
    Navbar,
    Typography,
    IconButton,
    Menu,
    MenuHandler,
    MenuList,
} from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { HiBars3CenterLeft } from "react-icons/hi2";
import { RxCross2 } from "react-icons/rx";

import logo from '../../Assets/Logo/logo.svg'


const navLinks = [
    {
        title: "Home",
        links: "/",
    },
    {
        title: "Shop",
        links: "/shop",
    },
    {
        title: "About Us",
        links: "/about-us",
    },
    {
        title: "Blogs",
        links: "/blogs",
    },
    {
        title: "Contact Us",
        links: "/contact-us",
    },
]


const NavBar = () => {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    const navList = (
        <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            {
                navLinks?.map((ele) => <Typography key={ele.title}
                    as="li"
                    variant="small"
                    color="blue-gray"
                    className="p-1 font-normal"
                >
                    <Link to={ele.links} className="flex items-center">
                        {ele.title}
                    </Link>
                </Typography>)
            }
        </ul>
    );

    return (
        <div className=" max-h-[768px] w-full overflow-hidden sticky top-0 z-[99999]">
            <Navbar className="sticky top-0 z-10 h-max rounded-none px-4 py-2 lg:px-8 lg:py-4 max-w-[1600px] mx-auto shadow-md mb-2 bg-white backdrop-blur-none bg-opacity-100  ">
                <div className="flex items-center justify-between text-blue-gray-900 lg:w-11/12 xl:w-10/12 mx-auto">
                    <Link
                        to="/"
                        className="mr-4 cursor-pointer py-1.5 font-medium"
                    >
                        <img src={logo} alt="dawar-logo" className='w-24' />
                    </Link>
                    <div className="flex items-center justify-between  gap-4 lg:w-[65%]">
                        <div className="mr-4 self-start hidden lg:block">{navList}</div>
                        <div>
                            <Link to={"https://wa.link/ysutum"} className='bg-[#2B2B2B] text-white text-sm p-2 rounded-full py-1' >
                                Call Now
                            </Link>
                        </div>
                        <Menu>
                            <MenuHandler>

                                <IconButton
                                    variant="text"
                                    className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                                    ripple={false}
                                    onClick={() => setOpenNav(!openNav)}
                                >
                                    {openNav ? (
                                        <RxCross2 />
                                    ) : (
                                        <HiBars3CenterLeft size={30} className='rotate-180' />
                                    )}
                                </IconButton>
                            </MenuHandler>
                            <MenuList>
                                {navList}
                            </MenuList>
                        </Menu>
                    </div>
                </div>
            </Navbar>
        </div>
    );
}

export default NavBar