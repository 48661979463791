import React from 'react'
import { FaApple } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';


import iphone14 from '../../../Assets/SVG_images/Landingpage/iphone-14-img.svg';
import iphone14backImg from '../../../Assets/SVG_images/hero-apple-back-img.png'
 

const swiperArray = [
    {
        icon: <FaApple className='md:text-5xl text-6xl' />,
        phoneName: "iPhone 14 Series",
        offer: "Up to 10% off Voucher",
        img: iphone14,
        img2: iphone14backImg,

    },
    {
        icon: <FaApple className='md:text-5xl text-6xl' />,
        phoneName: "iPhone 14 Series",
        offer: "Up to 10% off Voucher",
        img: iphone14,
        img2: iphone14backImg,

    },
    {
        icon: <FaApple className='md:text-5xl text-6xl' />,
        phoneName: "iPhone 14 Series",
        offer: "Up to 10% off Voucher",
        img: iphone14,
        img2: iphone14backImg,

    },
    {
        icon: <FaApple className='md:text-5xl text-6xl' />,
        phoneName: "iPhone 14 Series",
        offer: "Up to 10% off Voucher",
        img: iphone14,
        img2: iphone14backImg,

    },
    {
        icon: <FaApple className='md:text-5xl text-6xl' />,
        phoneName: "iPhone 14 Series",
        offer: "Up to 10% off Voucher",
        img: iphone14,
        img2: iphone14backImg,

    },
]


const Herosection = () => {
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-5'>
            <Swiper
                modules={[Pagination, Autoplay]}
                loop
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                    delay: 2500
                }}
                pagination={{ clickable: true }}
                className='mySwiper'
            >
                {
                    swiperArray?.map((ele, i) => <SwiperSlide key={i}>
                        <SwiperCard {...ele} />

                    </SwiperSlide>
                    )
                }
            </Swiper>
        </section>
    )
}

export default Herosection


const SwiperCard = ({ phoneName, icon, offer, img, img2 }) => {
    return (
        <div className='relative bg-black md:py-10 py-6 lg:px-0 px-4 rounded-3xl text-white flex justify-between items-center flex-row'>
            <div className='w-5/12 mx-auto z-50'>
                <div className='w-36 md:w-auto flex justify-center md:justify-normal items-center md:gap-x-6 gap-x-2'>
                    {icon}
                    <p>
                        {phoneName}
                    </p>
                </div>
                <div className='flex flex-col items-start justify-center'>
                    <p className='hidden md:block font-semibold text-4xl lg:w-2/3   mt-14 text-center md:text-left'>
                        {offer}
                    </p>
                    <Link to={'/shop'} className='font-medium flex items-center gap-x-2 md:mt-2 mt-6'>
                        <p className='underline underline-offset-4 md:text-base text-xs'>
                            Shop Now
                        </p>
                        <IoMdArrowForward className='text-lg md:text-2xl' />
                    </Link>
                </div>

            </div>
            <img src={img2} alt={phoneName} className='md:absolute static w-40 2xl:w-64 xl:w-56 lg:w-52 md:w-44 z-0
            xl:left-[21rem] lg:left-72 left-60 bottom-2' />
            <div className='md:block hidden lg:w-4/12 md:w-5/12 lg:mr-20'>
                <img src={img} alt={phoneName} />
            </div>
            <div className='hidden'>
                <Link to={'/shop'} className='font-medium flex items-center gap-x-2 mt-2 justify-center md:justify-normal w-max mx-auto md:hidden'>
                    <p className='underline underline-offset-4'>
                        Shop Now
                    </p>
                    <IoMdArrowForward size={24} />
                </Link>
            </div>
            <p className='hidden md:block absolute bottom-5 right-6 text-xs text-[#A9A9A9] font-semibold '>
            *Subject to availability for a limited period only
            </p>
        </div>
    )
}