import React, { useState } from 'react'
import { Button, Input, Textarea } from '@material-tailwind/react'

const initalData = {
    email: "",
    phone: "",
    message: "",
}

const From = () => {
    const [formData, setFormData] = useState(initalData)


    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData)
        setFormData(initalData)
    }
    return (
        <div>
            <h2 className='text-[#F42C37] text-xl '>
                Contact Us
            </h2>
            <h2 className='font-bold text-3xl mt-5'>
                Get In Touch
            </h2>
            <p className='text-sm mt-6'>
                When, while lovely valley teems with vapour around meand meridian the upper impenetrable .
            </p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col gap-y-4 items-center'>
                        <div className='w-full'>
                            <Input
                                type='email'
                                className=" !border-none !bg-[#EFEFEF] text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-none focus:!border-t-gray-900 focus:ring-0 rounded-full font-['Montserrat']"
                                labelProps={{
                                    className: "hidden",
                                }}
                                placeholder='Your Email'
                                value={formData.email}
                                onChange={handleChange}
                                name='email'
                                required
                            />
                        </div>
                        <div className='w-full'>
                            <Input
                                type='tel'
                                className=" !border-none !bg-[#EFEFEF] text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-none focus:!border-t-gray-900 focus:ring-0 rounded-full font-['Montserrat']"
                                labelProps={{
                                    className: "hidden",
                                }}
                                placeholder='Your Phone Number'
                                value={formData.phone}
                                onChange={handleChange}
                                name='phone'
                                pattern='[0-9]{10}'
                                required
                            />
                        </div>
                        <div className='w-full'>
                            <Textarea
                                size='lg'
                                rows={5}
                                className=" !border-none !bg-[#EFEFEF] text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-none focus:!border-t-gray-900 focus:ring-0 rounded-[25px] font-['Montserrat']"
                                labelProps={{
                                    className: "hidden",
                                }}
                                placeholder='Your Message'
                                value={formData.message}
                                onChange={handleChange}
                                name='message'
                                required
                            />
                        </div>
                        <div className='w-full flex md:justify-end md:items-end md:me-24 justify-center items-center'>
                            <Button
                                type='submit'
                                size="sm"
                                className=" capitalize font-normal px-14 md:px-4 font-['Montserrat'] rounded-full bg-[#F42C37] "
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default From