import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import AboutUs from '../Pages/AboutUs'
import Blog from '../Pages/Blog'
import Landingpage from '../Pages/Landingpage'
import PrivacyPolicy from '../Pages/PrivacyPolicy'
import TermsAndCondation from '../Pages/TermsAndCondation'
import ProductPage from '../Pages/ProductPage'
import ContactUs from '../Pages/ContactUs'
import RefundPolicy from '../Pages/RefundPolicy'
import BlogDetailPage from '../Pages/BlogDetailPage'

const MainRoutes = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <Routes>
            <Route path='/' element={<Landingpage />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/blogs' element={<Blog />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-condition' element={<TermsAndCondation />} />
            <Route path='/shop' element={<ProductPage />} />
            <Route path='/contact-us' element={<ContactUs />} />
            {/* <Route path='/refund-policy' element={<RefundPolicy/>} /> */}
            <Route path='/blog/:id' element={<BlogDetailPage/>} />

        </Routes>
    )
}

export default MainRoutes;
