import React from 'react';
import './App.css';
import NavBar from './Components/NavBar/NavBar';
import MainRoutes from './AllRoutes/MainRoutes';
import Footer from './Components/Footer/Footer';
import ElectronicBrands from './Components/ElectronicsBrands/ElectronicBrands';

function App() {
  return (
    <>
      <NavBar />
      <MainRoutes />
      <ElectronicBrands />
      <Footer />

    </>
  );
}

export default App;
