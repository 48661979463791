
import React, { useEffect, useState } from 'react'
import { client } from '../sanity'
import PortableText from "react-portable-text";
import imageUrlBuilder from '@sanity/image-url';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import { Spinner } from "@material-tailwind/react";

const BlogDetailPage = () => {

    const params = useParams()
    const { id } = params;

    const [blogData, setBlogData] = useState('')
    const builder = imageUrlBuilder(client)

    const fetchData1 = async () => {
        const query = `*[_id == "${id}" ]`;
        const blogs = await client.fetch(query);
        setBlogData(blogs[0])
        // console.log("SANITY DATA AAAAAAAAAAAAAAAAAAA", blogs)
        // setBlogData(blogs)
    }
    useEffect(() => {
        fetchData1()
        window.scroll(0,0)
        
    }, [])


    return (
        <div className=' max-w-[1600px] m-auto lg:w-7/12 w-11/12 my-16'>
            {blogData._id ?
                <>
                    <div>
                        <h1 className='md:text-4xl text-3xl font-bold '>{blogData.title}</h1>
                    </div>
                    <div className='flex gap-x-2 mt-6'>
                        <img src='/apple-touch-icon.png' alt="the-bliss-events-logo" className='w-11 h-11 rounded-full' />
                        <div>
                            <p className='font-semibold'>Dawar Home Appliances</p>
                            <div className='text-[14px]'>
                                <Moment format="D MMM YYYY" withTitle>
                                    {blogData[0]?.createdAt}
                                </Moment>
                            </div>
                        </div>

                    </div>
                    <img src={`${builder.image(blogData?.blogimage).url()}`} alt='blog-image' className='mt-8' />
                    {/* <h3 className='md:text-2xl text-xl font-medium mt-4 mb-6'>{blogData.metadesc}</h3> */}

                    <PortableText
                        // Pass in block content straight from Sanity.io
                        content={blogData.content || []}
                        projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                        dataset={process.env.REACT_APP_SANITY_DATASET}
                        // Optionally override marks, decorators, blocks, etc. in a flat
                        // structure without doing any gymnastics
                        serializers={{
                            h1: (props) => <h1 style={{}} className='font-bold md:text-4xl text-3xl mt-4' {...props} />,
                            h2: (props) => <h2 style={{}} className='font-bold md:text-[38px] text-2xl mt-4' {...props} />,
                            h3: (props) => <h3 style={{}} className='font-bold md:text-3xl text-[30px]  mt-4' {...props} />,
                            h3: (props) => <h3 style={{}} className='font-bold md:text-3xl text-[26px] mt-4' {...props} />,
                            h3: (props) => <h3 style={{}} className='font-bold md:text-3xl text-[24px]  mt-4' {...props} />,
                            h4: (props) => <h4 style={{}} className='font-bold md:text-2xl text-xl mt-4' {...props} />,
                            h5: (props) => <h5 style={{}} className='font-bold md:text-xl mt-4' {...props} />,
                            h6: (props) => <h6 style={{}} className='font-bold md:text-lg mt-4' {...props} />,
                            normal: (props) => <p style={{}} className='mt-4 md:text-lg text-base' {...props} />,
                            ol: ({ children }) => <ol className="special-list-item list-decimal mt-2 md:text-lg text-base">{children}</ol>,
                            ul: ({ children }) => <ul className="special-list-item list-disc mt-2  md:text-lg text-base">{children}</ul>,
                            li: ({ children }) => <li className="special-list-item ml-4 mt-2  md:text-lg text-base">{children}</li>,
                        }}
                    />
                </>
                :
                <div className='flex justify-center items-center h-[40vh]'>
                    <Spinner className=' text-center md:h-20 md:w-20 h-16 w-16 ' />

                </div>
            }
        </div>
    )
}
export default BlogDetailPage;

