import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import imageUrlBuilder from '@sanity/image-url';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { client } from '../../../sanity';

// import cardImg1 from '../../../Assets/Blog/blog1.jpg'
// import cardImg2 from '../../../Assets/Blog/blog2.jpg'
// import cardImg3 from '../../../Assets/Blog/blog3.jpg'


// const blogCardArray = [
//     {
//         img: cardImg1,
//         title: "How to choose perfect gadgets",
//         date: "October 5, 2019",
//         writer: "By Paul",
//         desc: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper s",
//     },
//     {
//         img: cardImg2,
//         title: "How to choose perfect gadgets",
//         date: "October 5, 2019",
//         writer: "By Paul",
//         desc: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper s",
//     },
//     {
//         img: cardImg3,
//         title: "How to choose perfect gadgets",
//         date: "October 5, 2019",
//         writer: "By Paul",
//         desc: "When, while the lovely valley teems with vapour around me, and the meridian sun strikes the upper s",
//     },
// ]

const Blogs = () => {

    const [blogs, setBlogs] = useState([])

    const fetchData = async () => {
        const query = `*[_type == "blog"][0..2]`;
        const blogs = await client.fetch(query);
        // console.log("SANITY QUERY", blogs)
        setBlogs(blogs)
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-10'>
            <h2 className=' font-bold text-3xl text-center '>
                Recent Blogs
            </h2>
            <h3 className="text-black/30 font-['Montserrat'] text-center mt-2">
                There are many variations passages
            </h3>
            <div className='hidden md:grid grid-cols-1 md:grid-cols-3 md:gap-x-2 lg:gap-x-6 gap-y-6 mt-10 md:mt-16'>
                {
                    blogs?.length > 0 &&
                    blogs?.map((el, i) => <Link to={`/blog/${el._id}`} key={i}><BlogCard key={i} el={el} /></Link>)
                }

            </div>
            <div className=' mt-10 md:hidden'>
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    spaceBetween={50}
                    modules={[Autoplay]}
                    className='mySwiper'
                >
                    {
                        blogs?.length > 0 &&
                        blogs?.map((el, i) => <SwiperSlide key={el._id} >
                            <Link to={`/blog/${el._id}`} key={i}><BlogCard key={i} el={el} /></Link>
                        </SwiperSlide>)

                    }
                </Swiper>
            </div>
        </section>
    )
}

export default Blogs


const BlogCard = ({ el }) => {
    const builder = imageUrlBuilder(client)

    return (
        <div className='bg-[#F7F7F7] mb-7 md:mb-0 shadow-lg rounded-2xl'>
            {/* <img src={img} alt={ele.title} className='rounded-t-2xl object-cover aspect-video' /> */}
            <img src={`${builder.image(el.blogimage).url()}`} alt='blog-image'
                className='rounded-t-2xl object-cover aspect-video' />
            <div className='p-4'>
                <div className='flex gap-x-4 font-[Montserrat] mt-4 text-[#6D6D6D] font-bold'>

                    <div className=' flex gap-1'>
                        <div className='flex'>
                            <Moment format="MMM D" withTitle>
                                {el.createdAt}
                            </Moment>
                            <p>,</p>
                        </div>
                        <Moment format="YYYY" withTitle>
                            {el.createdAt}
                        </Moment>
                    </div>
                </div>

                <h3 className='text-xl font-bold mt-3 line-clamp-2'>
                    {el.title}
                </h3>
                <p className='font-[Montserrat] mt-2 line-clamp-3 h-[4.4rem] text-[#4B4B4B]'>
                    {el.metadesc}
                </p>
            </div>
        </div >
    )
}