import React, { useEffect } from 'react'
import Herosection from '../Components/Herosection/Herosection'
import BlogCards from '../Components/Blog/BlogCards/BlogCards'
import ElectronicBrands from '../Components/ElectronicsBrands/ElectronicBrands'

const Blog = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <div>
      <Herosection title="Blog" />
      <BlogCards />
    </div>
  )
}

export default Blog
